import {motion} from 'framer-motion'
import React from 'react'

import {varWrapEnter} from './variants'

export default function MotionContainer({open, children, ...other}) {
  return (
    <motion.div initial={false} animate={open && 'animate'} variants={varWrapEnter} {...other}>
      {children}
    </motion.div>
  )
}
