import { motion } from "framer-motion";
import React from "react";
import errorImage from "../../assets/images/logo.png";
import error_icon from "../../assets/svg/error_icon.svg";
import { MotionContainer, varBounceIn } from "../animate";

export default function Component() {
  return (
    <>
      <div className="h-screen w-screen overflow-y-scroll bg-blue-50 -ml-[14rem] -mt-[3.25rem]">
        <MotionContainer initial="initial" open className="flex h-full w-full flex-col items-center justify-center">
          <motion.div variants={varBounceIn}>
            <img alt="errorImage" src={errorImage} className="mb-2 h-24 w-24 rounded-lg" />
          </motion.div>
          <motion.div variants={varBounceIn}>
            <h1 className="text-center text-xl">
              <p id="errorFound">Sorry, we encountered an error</p>
            </h1>
          </motion.div>
          <p className="mt-5 w-8/12 text-center text-[#253759]">
            <p id="errorFoundText">
              Sorry, an error occured in the app. We are currently looking into. You can refresh the page to continue where you left off. We
              are sorry for the incovinience
            </p>
          </p>

          {/* <motion.div variants={varBounceIn}>
            <img alt="error_icon" src={error_icon} className="my-4 w-24" />
          </motion.div> */}

          <button
            data-testid="reload"
            onClick={() => (window.location.href = "/")}
            className="mt-5 justify-self-center bg-green-600 px-4 py-2 text-center text-white text-xl"
          >
            <p id="reload">Reload</p>
          </button>
        </MotionContainer>
      </div>
    </>
  );
}

Component.displayName = "Dashboard";
