import React from "react";

export default function CardStats({ statSubtitle, statTitle, statIconName, statIconColor, statDescription }) {
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
      <div className="flex-auto p-4">
        <div className="flex flex-wrap">
          <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
            <h5 className="text-blueGray-400 uppercase font-bold">{statSubtitle}</h5>
            <p className="font-medium ">{statTitle}</p>
            <p className="font-medium ">{statDescription}</p>
          </div>
          <div className="relative w-auto pl-4 flex-initial">
            <div
              className={
                "text-white p-3 text-center inline-flex items-center justify-center w-7 h-7 shadow-lg rounded-full " + statIconColor
              }
            >
              <i className={statIconName}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
