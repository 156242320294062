import React, { useEffect } from "react";
import HomeScreen from "components/HomeScreen";
import { useSelector } from "react-redux";
import { endOfToday, startOfToday } from "date-fns";
import { newFirestore } from "../../utils/firebase";
import { collectionGroup, onSnapshot, orderBy, query, where } from "firebase/firestore";

const HomeFreemium = () => {
  const { estateData, estateID } = useSelector((state) => ({
    userName: state.auth.userData.name,
    estateID: state.auth?.estateData?.estateID,
    estateData: state.auth?.estateData,
  }));

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{`Dashboard`}</h1>
        </div>
      </section>

      <section className="is-main-section" style={{ height: "70vh" }}>
        <div className="flex flex-col justify-center items-center w-full h-full">
          <p className="font-bold ">Home</p>
        </div>
      </section>
    </>
  );
};

const HomePremium = () => {
  const { estateData, estateID, estateType } = useSelector((state) => ({
    userName: state.auth.userData.name,
    estateID: state.auth?.estateData?.estateID,
    estateData: state.auth?.estateData,
    estateType: state.auth?.estateData?.type,
  }));

  const [accessCreated, setAccessCreated] = React.useState([]);

  useEffect(() => {
    let unsubscribe;

    if (estateID) {
      unsubscribe = onSnapshot(
        query(
          collectionGroup(newFirestore, "guestAccess"),
          where("estateID", "==", estateID),
          where("createTime", ">=", startOfToday()),
          where("createTime", "<=", endOfToday()),
          orderBy("createTime", "desc")
        ),
        (querySnapshot) => {
          var docs = [];
          querySnapshot.forEach((doc) => {
            const data = doc?.data();
            const timestamp = data?.timestamp?.toDate();
            const createTime = data?.createTime?.toDate();
            const expireTime = data?.expireTime?.toDate();
            const confirmRejectDate = data?.confirmRejectDate?.toDate();
            docs.push({ ...data, timestamp, createTime, expireTime, confirmRejectDate });
          });

          setAccessCreated(docs);
        }
      );
    }

    return unsubscribe;
  }, [estateID]);

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{`Dashboard`}</h1>
        </div>
      </section>

      <section className="is-main-section">
        <HomeScreen estateData={estateData} accessCreated={accessCreated} />
      </section>
    </>
  );
};

export { HomePremium, HomeFreemium };
