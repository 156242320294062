import { createAction } from "redux-act";
import { toastr } from "react-redux-toastr";
import { firebaseError } from "utils";
import { newFirestore, newFunction } from "../../utils/firebase";
import { deleteDocument, updateDocument } from "../api";
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import paths from "config/paths";

export const USERS_FETCH_DATA_INIT = createAction("USERS_FETCH_DATA_INIT");
export const USERS_FETCH_DATA_SUCCESS = createAction("USERS_FETCH_DATA_SUCCESS");
export const USERS_FETCH_DATA_FAIL = createAction("USERS_FETCH_DATA_FAIL");

export const USERS_DELETE_USER_SUCCESS = createAction("USERS_DELETE_USER_SUCCESS");
export const USERS_DELETE_USER_FAIL = createAction("USERS_DELETE_USER_FAIL");

export const USERS_SUCCESS = createAction("USERS_SUCCESS");
export const USERS_CREATE_USER_FAIL = createAction("USERS_CREATE_USER_FAIL");

export const USERS_MODIFY_USER_SUCCESS = createAction("USERS_MODIFY_USER_SUCCESS");
export const USERS_MODIFY_USER_FAIL = createAction("USERS_MODIFY_USER_FAIL");
export const USER_GET_ESTATE_DATA = createAction("USER_GET_ESTATE_DATA");

export const USERS_CLEAN_UP = createAction("USERS_CLEAN_UP");

export const USERS_CLEAR_DATA_LOGOUT = createAction("USERS_CLEAR_DATA_LOGOUT");

// COMMERCIAL/NINJA
export const GET_ALL_USER_ESTATES = createAction("GET_ALL_USER_ESTATES");

export const fetchUserEstates = ({ estateIDs, proceed = () => {} }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(USERS_FETCH_DATA_INIT());
      toastr.info("Fetching residential properties", "Please wait...");
      const ref = await getDocs(query(collection(newFirestore, `estates`), where("uid", "in", estateIDs)));
      const allUserEstates = {};
      ref.forEach((doc) => {
        allUserEstates[doc.id] = doc.data();
      });

      dispatch(
        GET_ALL_USER_ESTATES({
          allUserEstates,
        })
      );

      dispatch(USERS_SUCCESS());
      proceed();
      // onSnapshot(query(collection(newFirestore, `estates`), where("uid", "in", estateIDs)), async (querySnapshot) => {
      //   const allUserEstates = {};
      //   querySnapshot.forEach((doc) => {
      //     allUserEstates[doc.id] = doc.data();
      //   });

      //   dispatch(
      //     GET_ALL_USER_ESTATES({
      //       allUserEstates,
      //     })
      //   );

      //   dispatch(USERS_SUCCESS());
      // });
    } catch (error) {
      console.log(error);
      toastr.error("", error);
      return dispatch(USERS_FETCH_DATA_FAIL({ error }));
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch, getState) => {
    dispatch(USERS_FETCH_DATA_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .users.data.filter((user) => user.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteUserTask = deleteDocument("users", id);

    try {
      await Promise.all([deleteLogoTask, deleteUserTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_DELETE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success("", "The user was deleted.");
    return dispatch(USERS_DELETE_USER_SUCCESS({ id }));
  };
};

export const clearUsersDataLogout = () => {
  return (dispatch) => {
    dispatch(USERS_CLEAR_DATA_LOGOUT());
  };
};

export const createResident = (allData) => {
  return async (dispatch, getState) => {
    dispatch(USERS_FETCH_DATA_INIT());
    const { locale } = getState().preferences;

    let response;
    try {
      const addNewUser = httpsCallable(newFunction, "addNewUser");
      response = await addNewUser(allData);
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    if (response.data?.status) {
      toastr.success("", response.data?.response);

      allData?.reset({
        name: "",
        emailAddress: "",
        houseNumber: "",
        userGroup: "",
        userType: "",
        phoneNumber: "",
        gender: "",
        adults: "",
        kids: "",
        checkIn: "",
        checkOut: "",
      });

      return dispatch(USERS_SUCCESS());
    } else {
      toastr.error("", response.data?.response);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: response.data?.response,
        })
      );
    }
  };
};

export const addAdmin = (allData) => {
  return async (dispatch) => {
    dispatch(USERS_FETCH_DATA_INIT());

    let response;
    try {
      const addNewAdmin = httpsCallable(newFunction, "addNewAdmin");
      response = await addNewAdmin(allData);
    } catch (error) {
      const errorMessage = firebaseError(error.message);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    if (response.data?.status) {
      toastr.success("", response.data?.response, { timeOut: 0 });
      return dispatch(USERS_SUCCESS());
    } else {
      toastr.error("", response.data?.response);
      return dispatch(USERS_CREATE_USER_FAIL(response.data?.response));
    }
  };
};

export const addEmergencyContact = (allData) => {
  return async (dispatch) => {
    dispatch(USERS_FETCH_DATA_INIT());

    let response;
    try {
      const addNewEmergencyContact = httpsCallable(newFunction, "addNewEmergencyContact");

      response = await addNewEmergencyContact(allData);
    } catch (error) {
      const errorMessage = firebaseError(error.message);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    if (response.data?.status) {
      toastr.success("", response.data?.response);
      return dispatch(USERS_SUCCESS());
    } else {
      toastr.error("", response.data?.response);
      return dispatch(USERS_CREATE_USER_FAIL(response.data?.response));
    }
  };
};

export const modifyUser = ({ name, emailAddress, id, userGroup, gender, streetName }, estateID) => {
  return async (dispatch, getState) => {
    dispatch(USERS_FETCH_DATA_INIT());
    const { locale } = getState().preferences;

    const userData = {
      name,
      estateID,
      userGroup,
      uid: id,
      id,
      emailAddress,
      gender,
      streetName,
    };

    const updateUserDbTask = updateDocument(`estates/${estateID}/residents`, id, userData);
    try {
      const updateUserDetails = httpsCallable(newFunction, "updateUserDetails");
      await updateUserDetails({ userData });
    } catch (error) {
      console.log(error);

      toastr.error("", error?.message);
    }

    try {
      await Promise.all([updateUserDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success("", "User updated successfully");
    return dispatch(USERS_MODIFY_USER_SUCCESS({ user: userData, id }));
  };
};

export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());
