/**
 * An object representing the various paths used in the application.
 *
 * @typedef {Object} Paths
 * @property {"/"} ROOT - The root path.
 * @property {"/login"} LOGIN - The login path.
 * @property {"/residents"} RESIDENTS - The residents path.
 * @property {"/officers"} OFFICERS - The officers path.
 * @property {"/access-codes"} ACCESSCODES - The access codes path.
 * @property {"/admin/new"} ADD_ADMINS - The path to add new admins.
 * @property {"/admins"} ADMINS - The admins path.
 * @property {"/emergency-contacts"} EMERGENCY_CONTACTS - The emergency contacts path.
 * @property {"/users/new"} ADD_USER - The path to add a new user.
 * @property {"/emergency-contacts/new"} ADD_EMERGENCY_CONTACT - The path to add a new emergency contact.
 * @property {"/users/:id"} MODIFY_USER - The path to modify a user, with user ID as a parameter.
 * @property {"/profile"} PROFILE - The profile path.
 * @property {"/recover-password"} RESET_PASSWORD - The path to reset password.
 * @property {"/section"} SECTION - The section path.
 * @property {"/reports/view"} VIEWREPORTS - The path to view reports.
 * @property {"/estate-levy"} ESTATELEVY - The estate levy path.
 * @property {"/estate-levy/add-new-levy"} ADDNEWLEVY - The path to add a new levy.
 * @property {"/projects"} PROJECTS - The projects path.
 * @property {"/vehicle-payments"} VEHICLES - The vehicle payments path.
 * @property {"/projects/manage-projects"} ADDNEWPROJECT - The path to manage projects.
 * @property {"/messages"} MESSAGES - The messages path.
 * @property {"/chats"} CHATS - The chats path.
 * @property {"/shops"} SHOPS - The shops path.
 * @property {"/shop/new"} ADDNEWSHOP - The path to add a new shop.
 * @property {"/shop/inventory"} INVENTORY - The shop inventory path.
 * @property {"/shop/variances"} VARIANCES - The shop variances path.
 * @property {"/shop/orders"} ORDERS - The shop orders path.
 * @property {"/shop/transactions"} TRANSACTIONS - The shop transactions path.
 * @property {"/shop/setup"} SHOP_SETUP - The shop setup path.
 * @property {"/shop/admin"} SHOP_ADD_ADMIN - The path to add a shop admin.
 * @property {"/services"} SERVICES - The services path.
 * @property {"/services/new"} ADD_NEW_SERVICE_PERSON - The path to add a new service person.
 * @property {"/services/:id"} EDIT_NEW_SERVICE_PERSON - The path to edit a service person, with service person ID as a parameter.
 * @property {"/reports"} REPORTS - The reports path.
 * @property {"/visitors"} VISITORS - The visitors path.
 * @property {"/security"} SECURITY - The security path.
 * @property {"/admins-and-section-owners"} ADMINSANDSECTIONOWNERS - The admins and section owners path.
 * @property {"/sections"} SECTIONS - The sections path.
 * @property {"/sections/new"} ADDNEWSECTION - The path to add a new section.
 * @property {"/user/new"} ADD_HOTEL_USER - The path to add a new hotel user.
 * @property {"/landing"} LANDING - The commercial landing path.
 */

/**
 * @type {Paths}
 */
const paths = {
  ROOT: "/",
  LOGIN: "/login",
  RESIDENTS: "/residents",
  OFFICERS: "/officers",
  ACCESSCODES: "/access-codes",
  ADD_ADMINS: "/admin/new",
  ADMINS: "/admins",
  EMERGENCY_CONTACTS: "/emergency-contacts",
  ADD_USER: "/users/new",
  ADD_EMERGENCY_CONTACT: "/emergency-contacts/new",
  MODIFY_USER: "/users/:id",
  PROFILE: "/profile",
  RESET_PASSWORD: "/recover-password",
  SECTION: "/section",
  VIEWREPORTS: "/reports/view",
  ESTATELEVY: "/estate-levy",
  ADDNEWLEVY: "/estate-levy/add-new-levy",
  PROJECTS: "/projects",
  VEHICLES: "/vehicle-payments",
  ADDNEWPROJECT: "/projects/manage-projects",
  MESSAGES: "/messages",
  CHATS: "/chats",
  SHOPS: "/shops",
  ADDNEWSHOP: "/shop/new",
  INVENTORY: "/shop/inventory",
  VARIANCES: "/shop/variances",
  ORDERS: "/shop/orders",
  TRANSACTIONS: "/shop/transactions",
  SHOP_SETUP: "/shop/setup",
  SHOP_ADD_ADMIN: "/shop/admin",
  SERVICES: "/services",
  ADD_NEW_SERVICE_PERSON: "/services/new",
  EDIT_NEW_SERVICE_PERSON: "/services/:id",
  REPORTS: "/reports",
  VISITORS: "/visitors",
  SECURITY: "/security",
  ADMINSANDSECTIONOWNERS: "/admins-and-section-owners",
  SECTIONS: "/sections",
  ADDNEWSECTION: "/sections/new",
  ADD_HOTEL_USER: "/user/new",
  LANDING: "/landing",
};

export default paths;
