import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import paths from "../../config/paths";
import { endOfWeek, startOfWeek } from "date-fns";
import { newFirestore } from "../../utils/firebase";
import { useSelector } from "react-redux";
import { HomePremium } from "pages/Home";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";

const NotFound = React.lazy(() => import("pages/NotFound"));
const PrivateRoute = React.lazy(() => import("./PrivateRoute"));
const CommercialPrivateRoute = React.lazy(() => import("pages/Commercial/CommercialPrivateRoute"));
const ArrivingOrders = React.lazy(() => import("components/Dashboard/ArrivingOrders"));
const ShopDashboard = React.lazy(() => import("pages/ShopDashboard"));
const Login = React.lazy(() => import("pages/Login"));
const Users = React.lazy(() => import("pages/Users"));
const Profile = React.lazy(() => import("pages/Profile"));
const ResetPassword = React.lazy(() => import("pages/ResetPassword"));
const User = React.lazy(() => import("pages/User"));
const EmergencyContact = React.lazy(() => import("pages/EmergencyContact"));
const EmergencyContacts = React.lazy(() => import("pages/EmergencyContacts"));
const AccessCodes = React.lazy(() => import("pages/AccessCodes"));
const Messages = React.lazy(() => import("pages/Messages"));
const ViewReports = React.lazy(() => import("pages/Reports/ViewReports"));
const Officers = React.lazy(() => import("pages/Officers"));
const AddAdmin = React.lazy(() => import("pages/AddAdmin"));
const Admins = React.lazy(() => import("pages/Admins"));
const Shop = React.lazy(() => import("pages/Shop"));
const EstateLevy = React.lazy(() => import("pages/Finance/EstateLevy"));
const Projects = React.lazy(() => import("pages/Finance/Projects"));
const Vehicles = React.lazy(() => import("pages/Finance/Vehicles"));
const AddNewLevyForm = React.lazy(() => import("pages/Finance/AddNewLevy"));
const AddNewProject = React.lazy(() => import("pages/Finance/AddNewProject"));
const Inventory = React.lazy(() => import("pages/Inventory"));
const Orders = React.lazy(() => import("pages/Orders"));
const Transactions = React.lazy(() => import("pages/Transactions"));
const ShopSetup = React.lazy(() => import("pages/ShopSetup"));
const Shops = React.lazy(() => import("pages/Shops"));
const Service = React.lazy(() => import("pages/Service"));
const Services = React.lazy(() => import("pages/Services"));
const Chats = React.lazy(() => import("pages/Chats"));
const Variances = React.lazy(() => import("pages/Variances"));
const Reports = React.lazy(() => import("pages/Reports"));
const HotelDashboard = React.lazy(() => import("pages/Hotel/Dashboard"));
const Section = React.lazy(() => import("pages/Hotel/NewSection"));
const Visitors = React.lazy(() => import("pages/Hotel/Visitors"));
const Security = React.lazy(() => import("pages/Hotel/Security"));
const Sections = React.lazy(() => import("pages/Hotel/Sections"));
const AddUser = React.lazy(() => import("pages/Hotel/AddUser"));

//COMMERCIAL
const Landing = React.lazy(() => import("pages/Landing"));
const ResidentialDashboard = React.lazy(() => import("pages/Residential/ResidentialDashboard"));

const RouterComponent = () => {
  const { userType, shopId, claims } = useSelector((state) => ({
    userType: state.auth?.userData?.type || state.auth?.userData?.userType,
    claims: state.auth?.userData?.claims,
    shopId: state.auth.userData.shopId,
  }));

  const [ordersList, setOrdersList] = React.useState([]);
  useEffect(() => {
    let unsubscribe;

    if (userType?.includes("shop_")) {
      unsubscribe = onSnapshot(
        query(
          collection(newFirestore, `shops/${shopId}/orders`),
          where("verified", "==", true),
          where("timestamp", ">=", startOfWeek(new Date())),
          where("timestamp", "<=", endOfWeek(new Date())),
          orderBy("timestamp", "desc")
        ),

        (querySnapshot) => {
          var docs = [];
          querySnapshot.forEach((doc) => {
            const data = doc?.data();
            const timestamp = data?.timestamp?.toDate();
            docs.push({ ...data, timestamp });
          });

          setOrdersList(docs);
        }
      );
    }

    return unsubscribe;
  }, [userType]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Routes>
          <Route path={paths.LOGIN} element={<Login />} />
          <Route path={paths.RESET_PASSWORD} element={<ResetPassword />} />
          <Route
            path={paths.ADD_USER}
            element={
              <PrivateRoute>
                <User />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.MODIFY_USER}
            element={
              <PrivateRoute>
                <User />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADD_EMERGENCY_CONTACT}
            element={
              <PrivateRoute>
                <EmergencyContact />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.RESIDENTS}
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.OFFICERS}
            element={
              <PrivateRoute>
                <Officers />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.EMERGENCY_CONTACTS}
            element={
              <PrivateRoute>
                <EmergencyContacts />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADMINS}
            element={
              <PrivateRoute>
                <Admins />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.SHOPS}
            element={
              <PrivateRoute>
                <Shops />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ACCESSCODES}
            element={
              <PrivateRoute>
                <AccessCodes />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.MESSAGES}
            element={
              <PrivateRoute>
                <Messages />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.CHATS}
            element={
              <PrivateRoute>
                <Chats />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.PROFILE}
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.VIEWREPORTS}
            element={
              <PrivateRoute>
                <ViewReports />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADDNEWLEVY}
            element={
              <PrivateRoute>
                <AddNewLevyForm />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADDNEWPROJECT}
            element={
              <PrivateRoute>
                <AddNewProject />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.PROJECTS}
            element={
              <PrivateRoute>
                <Projects />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.VEHICLES}
            element={
              <PrivateRoute>
                <Vehicles />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ESTATELEVY}
            element={
              <PrivateRoute>
                <EstateLevy />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.REPORTS}
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />

          {/* service paths */}
          <Route
            path={paths.ADD_NEW_SERVICE_PERSON}
            element={
              <PrivateRoute>
                <Service />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.EDIT_NEW_SERVICE_PERSON}
            element={
              <PrivateRoute>
                <Service />
              </PrivateRoute>
            }
          />

          {/* shop paths */}
          <Route
            path={paths.ADDNEWSHOP}
            element={
              <PrivateRoute>
                <Shop />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.INVENTORY}
            element={
              <PrivateRoute>
                <Inventory />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.VARIANCES}
            element={
              <PrivateRoute>
                <Variances />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ORDERS}
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.TRANSACTIONS}
            element={
              <PrivateRoute>
                <Transactions />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.SHOP_SETUP}
            element={
              <PrivateRoute>
                <ShopSetup />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADD_ADMINS}
            element={
              <PrivateRoute>
                <AddAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.SERVICES}
            element={
              <PrivateRoute>
                <Services />
              </PrivateRoute>
            }
          />

          {/* hotel paths */}
          <Route
            path={paths.ADDNEWSECTION}
            element={
              <PrivateRoute>
                <Section />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.VISITORS}
            element={
              <PrivateRoute>
                <Visitors />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.SECURITY}
            element={
              <PrivateRoute>
                <Security />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.SECTIONS}
            element={
              <PrivateRoute>
                <Sections />
              </PrivateRoute>
            }
          />
          <Route
            path={paths.ADD_HOTEL_USER}
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />

          {/* commercial paths */}
          <Route path={paths.LANDING} element={<Landing />} />

          {/* root */}
          <Route
            path={paths.ROOT}
            element={
              userType?.includes("shop_") ? (
                <PrivateRoute>
                  <ShopDashboard {...{ ordersList }} />
                </PrivateRoute>
              ) : userType?.includes("hotel_") ? (
                <PrivateRoute>
                  <HotelDashboard />
                </PrivateRoute>
              ) : ["commercial/propmanager"].includes(claims?.userGroup) ? (
                <CommercialPrivateRoute>
                  <ResidentialDashboard />
                </CommercialPrivateRoute>
              ) : (
                <PrivateRoute>
                  <HomePremium />
                </PrivateRoute>
              )
            }
          />
          <Route element={<NotFound />} />
        </Routes>
        {userType?.includes("shop_") && <ArrivingOrders {...{ ordersList }} />}
      </BrowserRouter>
    </Suspense>
  );
};

export default RouterComponent;
